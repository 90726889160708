// Generated by Framer (1249b65)

import { addFonts, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./DrZNXvNyc-0.js";

const cycleOrder = ["mLBDw0Ebn"];

const variantClassNames = {mLBDw0Ebn: "framer-v-1pczdrd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {NTHK784vL: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "mLBDw0Ebn", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-J6rjE", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1pczdrd", className)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"mLBDw0Ebn"} ref={ref} style={{"--border-bottom-width": "1px", "--border-color": "rgb(255, 255, 255)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", boxShadow: "0px 0.6021873017743928px 3.010936508871964px -0.9166666666666666px hsla(0, 0%, 100%, 0.54398), 0px 2.288533303243457px 11.442666516217285px -1.8333333333333333px hsla(0, 0%, 100%, 0.52711), 0px 10px 50px -2.75px hsla(0, 0%, 100%, 0.45)", ...style}}/></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-J6rjE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-J6rjE .framer-1n2tza5 { display: block; }", ".framer-J6rjE .framer-1pczdrd { height: 403px; overflow: hidden; position: relative; width: 498px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 403
 * @framerIntrinsicWidth 498
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables false
 */
const FramerDrZNXvNyc: React.ComponentType<Props> = withCSS(Component, css, "framer-J6rjE") as typeof Component;
export default FramerDrZNXvNyc;

FramerDrZNXvNyc.displayName = "obrys c";

FramerDrZNXvNyc.defaultProps = {height: 403, width: 498};

addFonts(FramerDrZNXvNyc, [])